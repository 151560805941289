import { z } from 'zod';

import { passwordSchema } from '@/domain/user/validation';
import { TFunction } from '@/lib/translation/config';

export const registerSchema = (t: TFunction) =>
  z.object({
    email: z
      .string({
        required_error: t('auth.error.invalidEmail'),
      })
      .email(t('auth.error.invalidEmail')),
    password: passwordSchema(t),
    acceptRiskWarning: z.string().refine(
      (value) => {
        return value === ('on' as string);
      },
      {
        message: t('auth.error.checkboxMustBeChecked'),
      },
    ),
    acceptRiskCgu: z.string().refine(
      (value) => {
        return value === ('on' as string);
      },
      {
        message: t('auth.error.checkboxMustBeChecked'),
      },
    ),
    acceptsNewsLetter: z.string().nullable(),
    gRecaptchaToken: z.string({
      invalid_type_error: t('auth.error.missingRecaptcha'),
      required_error: t('auth.error.missingRecaptcha'),
    }),
    origin: z.string().optional(),
  });

export const socialRegisterSchema = (t: TFunction) =>
  z.object({
    acceptRiskWarning: z.string().refine(
      (value) => {
        return value === ('on' as string);
      },
      {
        message: t('auth.error.checkboxMustBeChecked'),
      },
    ),
    acceptRiskCgu: z.string().refine(
      (value) => {
        return value === ('on' as string);
      },
      {
        message: t('auth.error.checkboxMustBeChecked'),
      },
    ),
  });

export type RegisterPayload = z.infer<ReturnType<typeof registerSchema>>;
export type SocialRegisterPayload = z.infer<
  ReturnType<typeof socialRegisterSchema>
>;
