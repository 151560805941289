'use client';

import { zodResolver } from '@hookform/resolvers/zod';

import { useTranslations } from 'next-intl';
import { Suspense, use, useActionState } from 'react';
import { useForm } from 'react-hook-form';

import Layout from '@/app/[locale]/(auth)/_components/layout';
import SocialAuthList from '@/app/[locale]/(auth)/_components/social-auth-list';
import { Button } from '@/app/_components/ui/button';

import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/app/_components/ui/form';
import { Input } from '@/app/_components/ui/input';

import { Separator } from '@/app/_components/ui/separator';
import { signInSchema } from '@/domain/user/validation';
import clientConfig from '@/lib/config/client-config';
import { setFormErrors } from '@/lib/form-action';
import { ActionState } from '@/lib/form-action/type';
import Routes from '@/lib/router/routes';

import Link from '@/lib/translation/components/link';
import { useRouter } from '@/lib/translation/hooks';

import SideContent from './_components/side-content';
import { signIn } from './action';
import { SignInFormPayload } from './types';

interface SignInPageProps {
  searchParams: Promise<{
    email?: string;
    origin?: string;
    error?: string;
  }>;
}

const SignInClientPage = (props: SignInPageProps) => {
  // await checkIfUserIsConnectedOrRedirect(
  //   Routes.SignIn,
  //   generatePath(Routes.FavoriteProjects),
  // );
  const searchParams = use(props.searchParams);
  const t = useTranslations();
  const router = useRouter();

  const form = useForm<SignInFormPayload>({
    resolver: zodResolver(signInSchema(t)),
    defaultValues: {
      email: searchParams.email ?? '',
      password: '',
    },
    mode: 'onBlur',
  });

  const [, formAction] = useActionState<
    ActionState<SignInFormPayload>,
    FormData
  >(async (state, payload) => {
    form.clearErrors('root');
    const newState = await signIn(state, payload);

    if (!newState) {
      return null;
    }

    if (newState.status === 'error' && newState.errors) {
      setFormErrors(newState.errors, form);
    }

    if (newState.status === 'success') {
      router.push(searchParams.origin ?? Routes.Home);
    }

    return newState;
  }, null);

  const hasSearchParams =
    !!searchParams.email || !!searchParams.error || !!searchParams.origin;

  return (
    <Layout
      sideTitle={t('pages.signIn.sidebar.title')}
      side={<SideContent />}
      cta={
        <div className="flex w-full flex-col items-center gap-2 lg:items-start lg:gap-8">
          <Button form="sign-in" className="w-full lg:w-auto" type="submit">
            {t('auth.signIn.title')}
          </Button>

          {clientConfig.featureFlag.isSocialLoginEnabled ? (
            <Suspense>
              <SocialAuthList
                callbackUrl={searchParams?.origin ?? Routes.Home}
                sectionTitle={t('auth.signIn.socialLogin')}
              />
              {searchParams.error ? (
                <p className="text-destructive">{searchParams.error}</p>
              ) : null}
            </Suspense>
          ) : null}

          <Separator className="my-4" />
          <Button className="w-full lg:w-auto" variant="ghost" type="button">
            <Link href={Routes.SignUpRegister}>{t('auth.noAccountYet')}</Link>
          </Button>
        </div>
      }
    >
      {hasSearchParams && <meta name="robots" content="noindex, nofollow" />}
      <div className="flex flex-col gap-4 lg:gap-16">
        <h1 className="text-2xl font-semibold lg:text-4xl">
          {t('auth.signIn.title')}
        </h1>
        <Form {...form}>
          <form
            id="sign-in"
            action={formAction}
            className="flex flex-col gap-12 lg:items-start"
          >
            <div className="flex w-full flex-col gap-4">
              <FormField
                control={form.control}
                name="email"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>{t('auth.email')}</FormLabel>
                    <FormControl>
                      <Input
                        placeholder={t('auth.email')}
                        autoComplete="email"
                        type="email"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="password"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>{t('auth.password')}</FormLabel>
                    <FormControl>
                      <Input
                        placeholder={t('auth.password')}
                        autoComplete="current-password"
                        type="password"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <div className="flex justify-end">
                <Link href={Routes.ForgotPassword}>
                  {t('auth.forgotPassword.title')}
                </Link>
              </div>
            </div>
            {form.formState.errors.root ? (
              <p className="text-destructive">
                {form.formState.errors.root.message}
              </p>
            ) : null}
          </form>
        </Form>
      </div>
    </Layout>
  );
};

export default SignInClientPage;
