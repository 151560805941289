import { z } from 'zod';

import { TFunction } from '@/lib/translation/config';

export const passwordSchema = (t: TFunction) =>
  z
    .string({
      required_error: t('auth.error.invalidPassword'),
    })
    .min(8, t('auth.error.invalidPassword'))
    .regex(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+$/,
      t('auth.error.invalidPassword'),
    );

export const signInSchema = (t: TFunction) =>
  z.object({
    email: z.string().email(t('auth.error.invalidEmail')),
    password: passwordSchema(t),
  });

export const financialSituationSchema = (t: TFunction) =>
  z
    .object({
      income: z.number().optional(),
      perceivedInterest: z.number().optional(),
      perceivedDividend: z.number().optional(),
      perceivedRent: z.number().optional(),
      savings: z.number().optional(),
      financialInstrument: z.number().optional(),
      share: z.number().optional(),
      pension: z.number().optional(),
      rentAndLoanRepayment: z.number().optional(),
      insurance: z.number().optional(),
      currentExpenses: z.number().optional(),
      subscription: z.number().optional(),
      tax: z.number().optional(),
      totalDeclared: z.number().optional(),
    })
    .superRefine(
      (
        {
          totalDeclared,
          income,
          perceivedInterest,
          perceivedDividend,
          perceivedRent,
          savings,
          financialInstrument,
          share,
          pension,
          rentAndLoanRepayment,
          insurance,
          currentExpenses,
          subscription,
          tax,
        },
        context,
      ) => {
        const fields = {
          income,
          perceivedInterest,
          perceivedDividend,
          perceivedRent,
          savings,
          financialInstrument,
          share,
          pension,
          rentAndLoanRepayment,
          insurance,
          currentExpenses,
          subscription,
          tax,
        };
        if (totalDeclared === null || totalDeclared === undefined) {
          if (
            Object.values(fields).filter(
              (field) => field === undefined || field === null,
            ).length === Object.values(fields).length
          ) {
            context.addIssue({
              code: z.ZodIssueCode.custom,
              message: t('auth.error.requiredField'),
              path: ['totalDeclared'],
            });
          } else {
            Object.entries(fields)
              .filter((field) => field[1] === undefined || field[1] === null)
              .forEach((field) =>
                context.addIssue({
                  code: z.ZodIssueCode.custom,
                  message: t('auth.error.requiredField'),
                  path: [field[0]],
                }),
              );
          }
        }
      },
    );
